// -------------- THEME SWITCHER -------------- //
$theme-map: null;
$themes: (
  light: (
    text: #333333,
    bg: #ffffff,
    brand: #567567,
  ),
  dark: (
    text: #ffffff,
    bg: #333333,
    brand: #abcabc,
  ),
);

@mixin themify($themes) {

  @each $name,
  $values in $themes {
    .#{$name}-theme {
      $theme-map: $values !global;
      @content;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
@include themify($themes) {
  // p {
  //   color: themed('text');
  //   background-color: themed('bg');
  // };
  w {
    color: themed('text');
    background-color: themed('bg');
  };
  html, body {
    color: themed('text');
    background: themed('bg');
  }
  back {
    color: themed('text');
  }
};

@media (prefers-color-scheme: dark) {
  body[a="auto"] {
    .dark-theme & {
      color: themed('text');
      background: themed('bg');
    }
  }
}

// -------------------------------------------- //

// bg color is also needed in html in order to
// block body's background propagation
// see: https://stackoverflow.com/a/61265706
html, body { background: white; }

html { height: 100%; }

body {
  color: black;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  min-height: 100%;
  overflow-wrap: break-word;
}

.post-meta { text-align: right; }

h2, h3, h4, h5, h6 { margin-top: 3rem; }

hr { margin: 2rem 0; }

p { margin: 1rem 0; }

li { margin: 0.4rem 0; }

*:target { background: yellow; }

.w {
  max-width: 640px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

hr {
  text-align: center;
  border: 0;

  &:before { content: '/////' }
  &:after { content: attr(data-content) '/////' }
}

table { width: 100%; }

table, th, td {
  border: thin solid;
  border-collapse: collapse;
  padding: 0.4rem;
}

div.highlighter-rouge code {
  display: block;
  overflow-x: auto;
  white-space: pre-wrap;
  padding: 1rem;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1rem;

  p { margin: 0; }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}